import { Supplier } from "../generated/graphql";

export function getSupplierNameById(suppliers: Supplier[], id: number): string {
  const supplier = suppliers.find(s => s.id == id);
  if (supplier) {
    return supplier.name;
  }
  return "";
}

export function compareSupplierNames(
  suppliers: Supplier[],
  idA: number,
  idB: number
): number {
  const nameA = getSupplierNameById(suppliers, idA);
  const nameB = getSupplierNameById(suppliers, idB);
  return nameA.localeCompare(nameB);
}

export function getSupplierHasCheckAvailabilityById(
  suppliers: Supplier[],
  id: number
): boolean {
  let returnBool = false;
  const supplier = suppliers.find(s => s.id == id);
  if (supplier && supplier.hasAvailabilityCheck) {
    returnBool = true;
  }
  return returnBool;
}

export function getsupplierIdByName(suppliers: Supplier[], n: string): number {
  const supplier = suppliers.find(s => s.name === n);
  if (supplier) {
    return supplier.id;
  }
  return -1;
}
