import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { useListSuppliersQuery } from "../../../generated/graphql";
import { getSupplierNameById } from "../../../helperFunctions/SupplierHelper";

import {
  Container,
  Contents,
  StyledAvatar,
  MainContent,
  Footer
} from "./StyledElements";
import LinkButton from "../../LinkButton";
import NotificationActionsMenu from "../NotificationActionsMenu";

let suppliers = [];

const AvailabilityNotification = ({ notification }) => {
  const {
    data: supplierData,
    loading: supplierLoading,
    error: supplierError
  } = useListSuppliersQuery();
  if (supplierData) {
    suppliers = supplierData.listSuppliers;
  }

  return (
    <Container read={notification.read}>
      <Badge
        color="error"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        variant="dot"
        invisible={notification.read}
      >
        <StyledAvatar color="#960393">A</StyledAvatar>
      </Badge>
      <Contents>
        <MainContent>
          <Message notification={notification} />
        </MainContent>
        <Footer>
          <Typography variant="caption">
            {dayjs(notification.created_date).format("MM/DD/YYYY")} at{" "}
            {dayjs(notification.created_date).format("h:mm a")}
          </Typography>
        </Footer>
      </Contents>
      <NotificationActionsMenu notification={notification} />
    </Container>
  );
};
export default AvailabilityNotification;
AvailabilityNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    read: PropTypes.bool.isRequired,
    created_date: PropTypes.string.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      external_id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      supplier: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  })
};

/**
 * Display the correct message depending on the existence of shortages related to this message
 */
const Message = ({ notification }) => {
  const history = useHistory();
  const navigateToPurchasing = shortage => {
    const route = `/shortages?view=active&selectedPanel=purchasing&shortage=${shortage.id}`;
    history.push(route);
  };
  if (notification.optionalShortageListing)
    return (
      <PrimaryProductMessage
        notification={notification}
        navigateToPurchasing={navigateToPurchasing}
      />
    );

  return notification.favoriteAlternatives.length > 0 ? (
    <RelatedShortagesMessage
      notification={notification}
      navigateToPurchasing={navigateToPurchasing}
    />
  ) : (
    <NoRelatedShortageMessage notification={notification} />
  );
};

const PrimaryProductMessage = ({ notification, navigateToPurchasing }) => {
  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Available: </b>Inventory update from{" "}
        {getSupplierNameById(suppliers, notification.product.supplierId)} re:{" "}
        <LinkButton
          onClick={() =>
            navigateToPurchasing(notification.optionalShortageListing)
          }
          text={notification.product.description}
        />{" "}
        (CIN: {notification.product.external_id}).
      </Typography>
    </div>
  );
};
const RelatedShortagesMessage = ({ notification, navigateToPurchasing }) => {
  // the first button has a more detailed description than the rest
  const buttons = notification.favoriteAlternatives.reduce((a, b) => {
    const buttonText =
      a.length === 0
        ? `${b.primary_product.description} (CIN: ${b.primary_product.external_id})`
        : `CIN: ${b.primary_product.external_id}`;
    a.push(
      <LinkButton
        key={b.id}
        onClick={() => navigateToPurchasing(b)}
        text={buttonText}
      />
    );
    return a;
  }, []);

  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Available: </b>Inventory update from{" "}
        {getSupplierNameById(notification.product.supplierId)} re: favorited
        alternative {notification.product.description} (CIN:{" "}
        {notification.product.external_id}) for shortage
        {notification.favoriteAlternatives.length > 1 ? "s" : ""}{" "}
        {joinWithAnd(buttons)}.
      </Typography>
    </div>
  );
};
const joinWithAnd = items => {
  if (items.length <= 1) return items;
  return items.reduceRight((a, b) => {
    if (a === null) return ["and", b];
    a.unshift([b, ", "]);
    return a;
  }, null);
};

const NoRelatedShortageMessage = ({ notification }) => (
  <Typography
    display="inline"
    style={{ fontSize: ".875rem", lineHeight: "1.2" }}
  >
    <b>Available: </b>Inventory update from{" "}
    {getSupplierNameById(notification.product.supplierId)} re:{" "}
    {notification.product.description} (CIN: {notification.product.external_id}){" "}
    <i>(Archived Shortage)</i>.
  </Typography>
);
