import { gql } from "@apollo/client";

// For dashboard... rename
export const ALL_SHORTAGES_QUERY = gql`
  query AllShortages {
    allShortages {
      id
      entity_status
      priorityId
    }
  }
`;

export const SHORTAGE_LISTING = gql`
  query ShortageListing($active: Boolean) {
    allShortages(active: $active) {
      id
      bundles {
        id
        product {
          id
          external_id
        }
      }
      archived_date
      archived_by
      entity_status
      priorityId
      display_name
      usageRateOverride
      drug {
        id
        med_identifier
      }
      created_date
      updated_date
      lastEdited
      start_date
      labels {
        id
        label {
          id
          text
          isArchived
        }
        shortage_listing {
          id
        }
      }
      primary_product {
        id
        ndc
        description
        external_id
        generic_name
        dailymed_hyperlink
        form
        size
        strength
        supplierId
        activeShortageListing {
          id
        }
        latestArchivedListing {
          id
        }
      }
    }
  }
`;

export const GET_SHORTAGE = gql`
  query GetShortage($id: Int!) {
    getShortage(id: $id) {
      id
      entity_status
      archived_date
      archived_by
      usageRateOverride
      bundles {
        id
        product {
          id
          drug {
            id
          }
          generic_name
          dailymed_hyperlink
          size
          strength
          form
          external_id
          supplierId
          ndc
        }
      }
      display_name
      created_date
      created_by
      updated_date
      lastEdited
      priorityId
      supply_status
      primary_product {
        id
        external_id
        ndc
        form
        supplierId
        dailymed_hyperlink
      }
      drug {
        id
        med_identifier
        isVaultDrug
      }
    }
  }
`;

export const GET_SHORTAGE_DISPLAY_NAME = gql`
  query GetShortage($id: Int!) {
    getShortage(id: $id) {
      id
      display_name
    }
  }
`;

export const LIST_DISPENSERS = gql`
  query ListDispensers($shortage_id: Int!) {
    listDispensers(shortage_id: $shortage_id) {
      name
      dispensers {
        id
        current_level
        updated_date
        created_date
        dispenser {
          id
          dispenser_type
          name
        }
      }
    }
  }
`;

export const LIST_DRUG_PRODUCTS = gql`
  query ListDrugProducts($search: String) {
    listDrugProducts(search: $search) {
      generic_name
      ndc
      external_id
      drug {
        id
        med_identifier
      }
      id
      size
      strength
      form
      supplierId
      activeShortageListing {
        id
      }
      latestArchivedListing {
        id
      }
    }
  }
`;

export const CREATE_SHORTAGE = gql`
  mutation CreateShortage(
    $campus: Int!
    $display_name: String!
    $drug: Int!
    $primary_product: Int!
    $priorityId: Int!
    $status: Int!
    $internal_comment: String
    $restrictions: String
    $supply_status: String
    $start_date: Time
    $usage_rate: Float
    $create_from: Int
  ) {
    createShortage(
      campus: $campus
      display_name: $display_name
      drug: $drug
      primary_product: $primary_product
      priorityId: $priorityId
      status: $status
      internal_comment: $internal_comment
      restrictions: $restrictions
      supply_status: $supply_status
      start_date: $start_date
      usage_rate: $usage_rate
      create_from: $create_from
    ) {
      id
      bundles {
        id
        product {
          id
        }
      }
      archived_date
      archived_by
      entity_status
      priorityId
      display_name
      usageRateOverride
      drug {
        id
        med_identifier
        replenishmentRate {
          drugId
          calculated
        }
      }
      created_date
      updated_date
      lastEdited
      start_date
      labels {
        id
        label {
          id
          text
          isArchived
          created_date
        }
        shortage_listing {
          id
          display_name
        }
      }
      primary_product {
        id
        ndc
        description
        external_id
        generic_name
        dailymed_hyperlink
        form
        size
        strength
        supplierId
        activeShortageListing {
          id
        }
        latestArchivedListing {
          id
        }
        orders {
          id
          purchasingAccount {
            id
          }
          backorder {
            isResolved
          }
          shortedOrder {
            isResolved
          }
          droppedBackorder {
            isResolved
          }
        }
        purchaseRate {
          productID
          recentRate
          baselineRate
        }
      }
      campusRunways {
        campus {
          id
          name
        }
        total {
          total
          runwayWeeks
          validAsOfDate
        }
        centralPharmacy {
          total
          runwayWeeks
          validAsOfDate
        }
      }
      organizationRunway {
        total {
          total
          runwayWeeks
          validAsOfDate
        }
        centralPharmacy {
          total
          runwayWeeks
          validAsOfDate
        }
      }
      alternativeStrategies {
        id
        name
        alternativeStrategyProducts {
          id
          alternativeProductStatus
          product {
            description
            external_id
            form
            ndc
            cin
            id
            strength
            size
            manufacturer
            supplierId
            generic {
              id
              name
            }
            currentAvailabilities {
              id
              productId
              supplierId
              quantity
              createdBy
              createdDate
              quantityString
              allocation
              purchasingAccount {
                id
              }
            }
            currentPrices {
              id
              price
              createdDate
              account {
                id
                name
              }
            }
            eta {
              id
              date
              message
            }
          }
        }
      }
      include_in_report
    }
  }
`;

export const UPDATE_USAGE_RATE = gql`
  mutation UpdateUsageRate($drugID: Int!, $usageRateOverride: Float) {
    updateUsageRate(drugID: $drugID, usageRateOverride: $usageRateOverride) {
      id
      usageRateOverride
    }
  }
`;

export const DELETE_SHORTAGE_LABEL = gql`
  mutation DeleteShortageLabel($label_id: [Int]) {
    deleteShortageLabel(id: $label_id)
  }
`;

export const CREATE_LABEL = gql`
  mutation CreateLabel($text: String!) {
    createLabel(text: $text) {
      id
      text
      created_by
      created_date
    }
  }
`;

export const CREATE_SHORTAGE_LABELS = gql`
  mutation CreateShortageLabels($labels: [InputLabels]) {
    createShortageLabels(labels: $labels) {
      id
      shortage_listing {
        id
        display_name
      }
      label {
        id
        text
        isArchived
      }
    }
  }
`;

export const LIST_LABELS = gql`
  query ListLabels {
    listLabels {
      id
      text
      created_by
      created_date
    }
  }
`;

export const GET_FIRST_ORGANIZATION = gql`
  query GetFirstOrganization {
    getFirstOrganization {
      id
      name
      supportedByMDX
    }
  }
`;

export const GET_SUPPLY_STATUS_BY_SHORTAGE = gql`
  query GetSupplyStatusByShortage($shortage_id: Int!) {
    getSupplyStatusByShortage(shortage_id: $shortage_id) {
      id
      shortage_id
      updated_date
      note
      allocation
      purchased
      backordered
      etas_are_active
      allocation_detail
    }
  }
`;

export const UPDATE_SUPPLY_STATUS_NOTE = gql`
  mutation UpdateSupplyStatus($id: Int!, $note: String!) {
    updateSupplyStatus(id: $id, note: $note) {
      id
      updated_date
      note
    }
  }
`;

export const UPDATE_SUPPLY_STATUS_ALLOCATION_NOTE = gql`
  mutation UpdateSupplyStatus($id: Int!, $allocation: String) {
    updateSupplyStatus(id: $id, allocation: $allocation) {
      id
      updated_date
      allocation
    }
  }
`;

export const UPDATE_SUPPLY_STATUS_ALLOCATION_DETAIL = gql`
  mutation UpdateSupplyStatus($id: Int!, $allocation_detail: String) {
    updateSupplyStatus(id: $id, allocation_detail: $allocation_detail) {
      id
      updated_date
      allocation_detail
    }
  }
`;

export const UPDATE_SUPPLY_STATUS_PURCHASED = gql`
  mutation UpdateSupplyStatus($id: Int!, $purchased: Boolean) {
    updateSupplyStatus(id: $id, purchased: $purchased) {
      id
      updated_date
      purchased
    }
  }
`;

export const UPDATE_SUPPLY_STATUS_BACKORDERED = gql`
  mutation UpdateSupplyStatus($id: Int!, $backordered: Boolean) {
    updateSupplyStatus(id: $id, backordered: $backordered) {
      id
      updated_date
      backordered
    }
  }
`;

export const UPDATE_SUPPLY_STATUS_ETAS = gql`
  mutation UpdateSupplyStatus($id: Int!, $etas_are_active: Boolean) {
    updateSupplyStatus(id: $id, etas_are_active: $etas_are_active) {
      id
      updated_date
      etas_are_active
    }
  }
`;

export const GET_EXTERNAL_COMMUNICATION_BY_SHORTAGE = gql`
  query GetExternalCommunicationByShortage($shortage_id: Int!) {
    getExternalCommunicationByShortage(shortage_id: $shortage_id) {
      id
      shortage_id
      updated_date
      note
      visible
    }
  }
`;

export const UPDATE_EXTERNAL_COMMUNICATION_NOTE = gql`
  mutation UpdateExternalCommunication($id: Int!, $note: String!) {
    updateExternalCommunication(id: $id, note: $note) {
      id
      note
      updated_date
    }
  }
`;

export const UPDATE_EXTERNAL_COMMUNICATION_VISIBILITY = gql`
  mutation UpdateExternalCommunication($id: Int!, $visible: Boolean) {
    updateExternalCommunication(id: $id, visible: $visible) {
      id
      visible
      updated_date
      updated_by
    }
  }
`;

export const GET_IT_CHANGES_BY_SHORTAGE = gql`
  query GetITChangesByShortage($shortage_id: Int!) {
    getITChangesByShortage(shortage_id: $shortage_id) {
      id
      shortage_id
      updated_date
      note
    }
  }
`;

export const UPDATE_IT_CHANGES = gql`
  mutation UpdateITChanges($id: Int!, $note: String!) {
    updateITChanges(id: $id, note: $note) {
      id
      note
      updated_date
    }
  }
`;

export const GET_RESTRICTION_BY_SHORTAGE = gql`
  query GetRestrictionByShortage($shortage_id: Int!) {
    getRestrictionByShortage(shortage_id: $shortage_id) {
      id
      shortage_id
      updated_date
      note
      is_active
    }
  }
`;

export const UPDATE_RESTRICTION_TEXT = gql`
  mutation UpdateRestriction($id: Int!, $note: String) {
    updateRestriction(id: $id, note: $note) {
      id
      note
      is_active
      updated_date
    }
  }
`;

export const UPDATE_RESTRICTION_IS_ACTIVE = gql`
  mutation UpdateRestriction($id: Int!, $is_active: Boolean) {
    updateRestriction(id: $id, is_active: $is_active) {
      id
      note
      is_active
      updated_date
    }
  }
`;

export const GET_CUSTOM_REPORTS = gql`
  query GetCustomReports {
    getCustomReports {
      name
    }
  }
`;

export const DOWNLOAD_CUSTOM_REPORT = gql`
  query DownloadCustomReport($name: String!) {
    downloadCustomReport(name: $name) {
      base64_content
    }
  }
`;

export const GET_NOTES_FOR_SHORTAGE = gql`
  query GetNotesForShortage($shortage_id: Int!) {
    getNotesForShortage(shortage_id: $shortage_id) {
      id
      shortage_id
      note
      meeting_date
      is_published
      created_date
      created_by
      updated_date
      updated_by
      title
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($id: Int!) {
    deleteNote(id: $id) {
      id
      shortage_id
      note
      meeting_date
      is_published
      created_date
      created_by
      updated_date
      updated_by
      title
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote(
    $id: Int!
    $note: String
    $date: Date
    $is_published: Boolean
    $title: String
  ) {
    updateNote(
      id: $id
      note: $note
      meeting_date: $date
      is_published: $is_published
      title: $title
    ) {
      id
      shortage_id
      note
      meeting_date
      is_published
      created_date
      created_by
      updated_date
      updated_by
      title
    }
  }
`;

export const PUBLISH_NOTE = gql`
  mutation UpdateNote($id: Int!) {
    updateNote(id: $id, is_published: true) {
      id
      shortage_id
      note
      meeting_date
      is_published
      created_date
      created_by
      updated_date
      updated_by
      title
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $shortage_id: Int!
    $note: String!
    $meeting_date: Date!
    $is_published: Boolean!
    $title: String
  ) {
    createNote(
      shortage_id: $shortage_id
      note: $note
      meeting_date: $meeting_date
      is_published: $is_published
      title: $title
    ) {
      id
      shortage_id
      note
      meeting_date
      is_published
      created_date
      created_by
      updated_date
      updated_by
      title
    }
  }
`;

export const LIST_ETAS = gql`
  query listETAs($supply_status_id: Int!) {
    listETAs(supply_status_id: $supply_status_id) {
      id
      supply_status_id
      updated_date
      note
      eta_date
      created_by_automation
    }
  }
`;

export const CREATE_ETA = gql`
  mutation createETA(
    $supply_status_id: Int!
    $note: String!
    $eta_date: Time!
  ) {
    createETA(
      supply_status_id: $supply_status_id
      note: $note
      eta_date: $eta_date
    ) {
      id
      supply_status_id
      updated_date
      note
      eta_date
      created_by_automation
    }
  }
`;

export const UPDATE_ETA_NOTE = gql`
  mutation updateETA($id: Int!, $note: String!) {
    updateETA(id: $id, note: $note) {
      id
      note
      updated_date
    }
  }
`;

export const UPDATE_ETA_DATE = gql`
  mutation updateETA($id: Int!, $eta_date: Time!) {
    updateETA(id: $id, eta_date: $eta_date) {
      id
      eta_date
      updated_date
    }
  }
`;

export const DELETE_ETA = gql`
  mutation deleteETA($id: Int!) {
    deleteETA(id: $id) {
      id
    }
  }
`;

export const GET_EXTERNAL_COMMUNICATION_ACTIVITY = gql`
  query GetActivity($shortage_id: Int!, $table_name: String!) {
    getActivity(shortage_id: $shortage_id, table_name: $table_name) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        note
        visible
        updated_by
        updated_date
      }
      changed_fields {
        note
        visible
        updated_by
        updated_date
      }
    }
  }
`;

export const GET_MEETING_NOTE_ACTIVITY = gql`
  query GetActivity($shortage_id: Int!, $table_name: String!) {
    getActivity(shortage_id: $shortage_id, table_name: $table_name) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        id
        note
        meeting_date
        is_published
        title
        updated_by
        updated_date
      }
      changed_fields {
        note
        meeting_date
        is_published
        title
        updated_by
        updated_date
        deleted_date
        deleted_by
      }
    }
  }
`;

export const GET_ETA_ACTIVITY = gql`
  query GetETAActivity($supply_status_id: Int!) {
    getETAActivity(supply_status_id: $supply_status_id) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        id
        note
        eta_date
        updated_by
        updated_date
      }
      changed_fields {
        note
        eta_date
        updated_by
        updated_date
        deleted_date
        deleted_by
      }
    }
  }
`;

export const GET_SUPPLY_STATUS_ACTIVITY = gql`
  query GetActivity($shortage_id: Int!, $table_name: String!) {
    getActivity(shortage_id: $shortage_id, table_name: $table_name) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        id
        note
        allocation
        allocation_detail
        purchased
        backordered
        etas_are_active
        updated_by
        updated_date
      }
      changed_fields {
        note
        allocation
        allocation_detail
        purchased
        backordered
        etas_are_active
        updated_by
        updated_date
      }
    }
  }
`;

export const GET_RESTRICTIONS_ACTIVITY = gql`
  query GetActivity($shortage_id: Int!, $table_name: String!) {
    getActivity(shortage_id: $shortage_id, table_name: $table_name) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        id
        note
        is_active
        updated_by
        updated_date
      }
      changed_fields {
        note
        is_active
        updated_by
        updated_date
      }
    }
  }
`;

export const GET_IT_CHANGES_ACTIVITY = gql`
  query GetActivity($shortage_id: Int!, $table_name: String!) {
    getActivity(shortage_id: $shortage_id, table_name: $table_name) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        id
        note
        updated_by
        updated_date
      }
      changed_fields {
        note
        updated_by
        updated_date
      }
    }
  }
`;

export const GET_ATTACHMENT_ACTIVITY = gql`
  query GetActivity($shortage_id: Int!, $table_name: String!) {
    getActivity(shortage_id: $shortage_id, table_name: $table_name) {
      event_id
      table_name
      action_tstamp_clk
      client_query
      row_data {
        id
        link
        url
        file_name
        bucket_key
        updated_by
        updated_date
      }
      changed_fields {
        link
        file_name
        updated_by
        updated_date
        deleted_by
      }
    }
  }
`;

export const GET_PRESIGNED_URL = gql`
  query getPresignedURL(
    $shortage_id: Int!
    $file_name: String!
    $content_type: String!
  ) {
    getPresignedURL(
      shortage_id: $shortage_id
      file_name: $file_name
      content_type: $content_type
    ) {
      upload_url
      shortage_id
    }
  }
`;

export const CREATE_ATTACHMENT = gql`
  mutation createAttachment(
    $file_name: String!
    $url: String!
    $shortage_id: Int!
    $is_managed: Boolean!
  ) {
    createAttachment(
      file_name: $file_name
      url: $url
      shortage_id: $shortage_id
      is_managed: $is_managed
    ) {
      id
      shortage_id
      file_name
      link
      is_managed
      updated_date
    }
  }
`;

export const LIST_ATTACHMENTS = gql`
  query getAttachmentsByShortage($shortage_id: Int!) {
    getAttachmentsByShortage(shortage_id: $shortage_id) {
      id
      shortage_id
      file_name
      link
      is_managed
      updated_date
      created_date
    }
  }
`;

export const UPDATE_ATTACHMENT = gql`
  mutation updateAttachment($id: Int!, $file_name: String!) {
    updateAttachment(id: $id, file_name: $file_name) {
      id
      shortage_id
      file_name
      updated_date
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: Int!) {
    deleteAttachment(id: $id) {
      id
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      details_panel_order
      id
      username
      authorized_pages
      selectedRunwayView
    }
  }
`;

export const GET_CURRENT_USER_WITH_PREFERENCES = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      username
      notification_email
      authorizedCampuses {
        id
        name
      }
      product_availability_notifications_settings {
        id
        createdBy
        createdDate
        updatedBy
        updatedDate
        userId
        deliver_in_app
        deliver_in_digest_email
        hasAllLabels {
          created_by
          created_date
          id
          text
        }
        hasPriorityIn
        mustBeNew
        hasActiveRestrictions
        hasPurchasedAllocation
        hasActiveBackorder
        hasActiveEta
        hasEtaAfter
        hasEtaBefore
      }
      task_notification_settings {
        id
        createdBy
        createdDate
        updatedBy
        updatedDate
        userId
        deliver_in_app
        deliver_in_digest_email
        notifyOnAssignment
        notifyOnCompletion
        notifyOnOverdue
      }
      usage_notification_settings {
        id
        createdBy
        createdDate
        updatedBy
        updatedDate
        userId
        deliver_in_app
        deliver_in_digest_email
        notifyOnRunwayBelowThreshold
        threshold
        notifyOnExcessUsage
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      username
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      id
      details_panel_order
    }
  }
`;

export const GET_ALL_SHORTAGE_TASKS = gql`
  query AllShortageTasks {
    allShortageTasks {
      id
      createdBy
      createdDate
      updatedBy
      updatedDate
      deletedBy
      deletedDate
      notes
      dueDate
      taskStatus
      assignedToUser {
        id
        username
      }
      followsTask {
        id
      }
      shortageListing {
        id
        display_name
        archived_date
      }
      taskPriority {
        id
        name
        rank
      }
      recurringUnit
      recurringPeriod
    }
  }
`;

export const UPDATE_SHORTAGE_TASK = gql`
  mutation UpdateShortageTask($input: UpdateTaskInput!) {
    updateShortageTask(input: $input) {
      id
      createdBy
      createdDate
      updatedBy
      updatedDate
      deletedBy
      deletedDate
      notes
      dueDate
      taskStatus
      assignedToUser {
        id
        username
      }
      followsTask {
        id
      }
      shortageListing {
        id
        display_name
        archived_date
      }
      taskPriority {
        id
        name
        rank
      }
      recurringUnit
      recurringPeriod
    }
  }
`;

export const LIST_TASK_PRIORITIES = gql`
  query ListTaskPriorities {
    listTaskPriorities {
      id
      name
      rank
    }
  }
`;
